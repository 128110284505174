<template>
  <div class="el-collapse-box">
    <AssociationScroll :showTabs="false">
      <AssociationScrollView title="个人信息" name="0">
        <div style="padding: 18px 12px 12px">
          <el-descriptions
            class="margin-top"
            labelClassName="policy-label-style"
            contentClassName="policy-label-style"
          >
            <el-descriptions-item label="姓名">
              <template slot="label"> 姓名 </template>
              {{ detailData.account || "--" }}
            </el-descriptions-item>
            <el-descriptions-item label="所属部门">
              <template slot="label"> 所属部门 </template>
              {{ detailData.deptName || "--" }}
            </el-descriptions-item>
            <!-- <el-descriptions-item label="团队角色">
              <template slot="label"> 团队角色 </template>
              {{ detailData.teamRole || "--" }}
            </el-descriptions-item> -->
          </el-descriptions>
        </div>
      </AssociationScrollView>
      <AssociationScrollView title="绩效信息" name="1">
        <div style="margin-top: 24px">
          <el-popover trigger="click">
            <div>
              <TimeRangeTable @submit="handleChangeYear"></TimeRangeTable>
            </div>
            <el-input
              style="max-width: 240px"
              slot="reference"
              v-model="yearmonth"
            ></el-input>
          </el-popover>

          <div style="height: 20px"></div>
          <el-descriptions
            class="margin-top"
            labelClassName="policy-label-style"
            contentClassName="policy-label-style"
          >
            <!-- <el-descriptions-item label="个人佣金金额：">
              <template slot="label"> 个人佣金金额</template>
              {{ detailData?.personageTitle?.commissionAmount || "---" }}
            </el-descriptions-item> -->
            <el-descriptions-item label="团队长金额：">
              <template slot="label"> 团队长金额</template>
              {{
                formatNumber(detailData?.personageTitle?.commissionAmount) ||
                "---"
              }}
            </el-descriptions-item>
            <!-- <el-descriptions-item label="合计金额：">
              <template slot="label"> 合计金额</template>
              <span
                style="font-weight: bold; font-size: 18px; color: #0080ff"
                >{{ detailData?.personageTitle?.totalAmount || "---" }}</span
              >
            </el-descriptions-item> -->
          </el-descriptions>
          <!-- 个人绩效 -->
          <!-- <div class="kpi-table">
            <div class="kpi-table-title">个人绩效详情</div>
            <div>
              <SimpleTable
                :tableProps="performanceTableProps"
                :queryFun="perQueryFun"
                ref="perTable"
              >
                <template slot="category" slot-scope="{ data }">
                  {{
                    findDict(data.row.category, "insCategoryTypes").dictValue
                  }}
                </template>
              </SimpleTable>
            </div>
          </div> -->
          <!-- 团队绩效 -->
          <div class="kpi-table">
            <div class="kpi-table-title">团队长绩效详情</div>
            <div>
              <SimpleTable
                :tableProps="teamTableProps"
                :queryFun="teamQueryFun"
                ref="teamTable"
                ><template slot="category" slot-scope="{ data }">
                  {{
                    findDict(data.row.category, "insCategoryTypes").dictValue
                  }}
                </template></SimpleTable
              >
            </div>
          </div>
        </div>
      </AssociationScrollView>
    </AssociationScroll>
  </div>
</template>

<script>
import TimeRangeTable from "@/views/routerPerformanceManagement/components/TimeRangeTable.vue";
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import SimpleTable from "@/components/SimpleTable/index.vue";
import dayjs from "dayjs";
//import { addThousandSeparator } from "@/utils/dealNumber";
import { getTeamKpiDetail } from "@/api/kpi.js";
import { insCategoryTypes } from "@/views/routerPerformanceManagement/const.js";
export default {
  components: {
    AssociationScroll,
    AssociationScrollView,
    TimeRangeTable,
    SimpleTable,
  },
  props: {
    // detailData: {
    //   type: Object,
    //   default: () => { },
    // },
    userInfo: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      //addThousandSeparator: addThousandSeparator,
      yearmonth: "",
      dictList: { insCategoryTypes: insCategoryTypes },
      isUpdateRecord: false,
      updateNew: [],
      updateOld: [],
      updateType: "附件",
      srcList: [],
      repetitionHistoryForm: {
        cardNo: "",
        salesmanId: "",
        dataType: "",
        orderType: "",
      },
      repetitionHistoryVisible: false,
      // loginIdentity: "",
      detailData: {},
      formData: {
        // commissionPageIndex: "",
        // commissionPageSize: "",
        month: "",
        // teamLeaderPageIndex: "",
        // teamLeaderPageSize: "",
        teamLeaderId: "",
        year: "",
        account: "",
      },
      performanceList: [],
      performanceTotal: 0,
      //个人绩效表格
      // performanceTableProps: {
      //   currentPageKey: "commissionPageIndex",
      //   currentSizeKey: "commissionPageSize",
      //   isFuzzyQuery: false,
      //   isSelection: false,
      //   isColumnSet: false,
      //   isHiddenSet: false,
      //   isSearch: false,
      //   isRefresh: false,
      //   height: 280,
      //   border: true,
      //   empty: {
      //     description: "请选择绩效周期",
      //     image: require("@/assets/images/kpi/per-empty.png"),
      //   },
      //   columnProps: [
      //     { type: "index", isShow: true, label: "ID" },
      //     { prop: "serialNo", isShow: true, label: "绩效编号" },
      //     { prop: "salesmanDeptName", isShow: true, label: "部门" },
      //     {
      //       prop: "primaryInsuranceCompanyName",
      //       isShow: true,
      //       label: "保险公司",
      //     },
      //     {
      //       prop: "primaryInsuranceBranchName",
      //       isShow: true,
      //       label: "分支机构",
      //     },
      //     { prop: "insuranceOfDate", isShow: true, label: "投保日期" },
      //     { prop: "category", isShow: true, label: "险种类型", slot: true },
      //     { prop: "performanceTypeName", isShow: true, label: "项目" },
      //     { prop: "projectName", isShow: true, label: "细项" },
      //     { prop: "agentName", isShow: true, label: "代理人" },
      //     { prop: "policyNo", isShow: true, label: "保单号" },
      //     { prop: "premium", isShow: true, label: "保费" },
      //     { prop: "insertType", isShow: true, label: "出单类型" },
      //     { prop: "commissionFee", isShow: true, label: "个人绩效费率" },
      //     { prop: "commissionAmount", isShow: true, label: "个人绩效金额" },
      //   ],
      // },
      //团队绩效表格
      teamTableProps: {
        currentPageKey: "pageIndex",
        currentSizeKey: "pageSize",
        isFuzzyQuery: false,
        isSelection: false,
        isColumnSet: false,
        isHiddenSet: false,
        isSearch: false,
        isRefresh: false,
        height: 280,
        border: true,
        empty: {
          description: "暂无绩效详情",
          image: require("@/assets/images/kpi/team-empty.png"),
        },
        columnProps: [
          { type: "index", isShow: true, label: "ID" },
          { prop: "serialNo", isShow: true, label: "绩效编号" },
          { prop: "salesmanDeptName", isShow: true, label: "部门" },
          {
            prop: "primaryInsuranceCompanyName",
            isShow: true,
            label: "保险公司",
          },
          {
            prop: "primaryInsuranceBranchName",
            isShow: true,
            label: "分支机构",
          },
          { prop: "insuranceOfDate", isShow: true, label: "投保日期" },
          { prop: "category", isShow: true, label: "险种类型", slot: true },
          { prop: "performanceTypeName", isShow: true, label: "项目" },
          { prop: "projectName", isShow: true, label: "细项" },
          { prop: "agentName", isShow: true, label: "代理人" },
          { prop: "policyNo", isShow: true, label: "保单号" },
          { prop: "premium", isShow: true, label: "保费" },
          { prop: "insertType", isShow: true, label: "出单类型" },
          { prop: "teamLeaderFee", isShow: true, label: "团队绩效费率" },
          { prop: "teamLeaderAmount", isShow: true, label: "团队绩效金额" },
        ],
      },
    };
  },
  watch: {
    "userInfo.userId": {
      handler(e) {
        this.formData.teamLeaderId = e;
        this.formData.year = this.userInfo?.year;
        this.formData.month = this.userInfo?.month;
        this.formData.account = this.userInfo?.account;
        this.yearmonth = `${this.userInfo?.year}年${this.userInfo?.month}月`;
        this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    getList() {
      getTeamKpiDetail(this.formData).then((res) => {
        this.detailData = res.data;
        // this.performanceTotal = res.data?.performanceRecordPage?.total;
        // this.performanceList = res?.data?.performanceRecordPage?.records || [];
      });
    },
    handleChangeYear(e) {
      //console.log(e);
      this.formData.year = dayjs(e.currentTime).format("YYYY");
      this.formData.month =
        e.currentType == "M" ? dayjs(e.currentTime).format("MM") : "";
      this.yearmonth = e.showText;
      // this.$refs.perTable.getData();
      this.$refs.teamTable.getData();
      //this.getList();
    },
    // async perQueryFun(params) {
    //   let res = await getStaffKpiDetail({ ...params, ...this.formData });
    //   this.detailData = res.data;
    //   return {
    //     localData: res?.data?.personageList?.records || [],
    //     total: res?.data?.personageList?.total || 0,
    //   };
    // },
    async teamQueryFun(params) {
      let res = await getTeamKpiDetail({ ...params, ...this.formData });
      this.detailData = res.data;
      return {
        localData: res?.data?.personageList?.records || [],
        total: res?.data?.personageList?.total || 0,
      };
    },
    findDict(key, type) {
      let obj =
        this.dictList[type]?.find((item) => {
          return item.dictKey == key;
        }) || {};
      return obj;
    },
    //设置小数位
    formatNumber(value, bit = 2) {
      if (value) {
        return value.toFixed(bit);
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
//
::v-deep .el-table {
  border-radius: unset;
}
::v-deep .action-container {
  padding: unset !important;
}
.kpi-table {
  border-top: 1px dashed #dedede;
  padding: 24px 0;
  &-title {
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    margin-bottom: 8px;
  }
}
//
.list-item {
  border-top: 1px dashed #999;
  padding-top: 15px;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
}
.personnel_repetition {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  padding: 8px 12px;
  margin-bottom: 24px;
  background: #cce6ff;
  border-radius: 8px;
  &_details {
    color: #0080ff;
    text-decoration: underline;
    cursor: pointer;
  }
}
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .insurance-company {
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    font-weight: bold;
    color: #4278c9;
    font-size: 16px;
    margin-top: 16px;
  }
  .clause-title {
    margin: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 20px;
  }
  .steps-title {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    .decoration {
      height: 20px;
      width: 6px;
      border-radius: 3px;
      background-color: #4278c9;
      margin-right: 10px;
      margin-left: 14px;
    }
    .decoration2 {
      flex: 1;
      height: 1px;
      margin-left: 8px;
      margin-right: 20px;
      background-color: #cccccc;
    }
  }
  .steps-box {
    height: calc(100% - 50px);
    padding-top: 24px;
    overflow: auto;
    .step {
      display: flex;
      &-left {
        position: relative;
        width: 6px;
        background-color: #e1e1e1;
        margin-right: 10px;
        margin-left: 14px;
        img {
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 16px;
          height: 16px;
        }
      }
      &-left2 {
        background-color: transparent;
      }
      &-right {
        flex: 1;
        .time {
          font-size: 12px;
          font-weight: 300;
          color: #333333;
          margin-bottom: 4px;
        }
        .step-body {
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.policy-label-style {
  align-items: center !important;
  font-size: 14px;
  color: #333333;
}
.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
  .ep-b {
    font-size: 14px;
    color: #0080ff;
    margin-left: 24px;
    cursor: pointer;
    border-bottom: 1px solid #0080ff;
  }
  .policy-ep-img {
    width: 20px;
    height: 20px;
    margin-right: 14px;
  }
}
</style>
